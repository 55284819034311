<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">

        <a class="navbar-brand" href="#">
            <img alt="Narco Dons Logo" src="../assets/images/logo.png">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item" v-for="i,index in items" :key="i">
                    <a class="nav-link" :class="{'active': index == 0}" aria-current="page" href="#">{{i.name}}</a>
                </li>
            </ul>
            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                <button v-if="loggedIn==false" class="btn btn-outline-warning me-4" type="button" @click="SwitchToSignup">Signup</button>
                <button v-if="loggedIn==false" class="btn btn-outline-primary me-4" type="button" @click="SwitchToLogin">Log In</button>
                <button v-if="loggedIn==true" class="btn btn-outline-primary me-4" type="button" @click="SwitchToLogout">Log Out</button>
            </div>
            <div v-if="loggedIn==true" class="avatar">
                <div v-html="avatar"></div>
                <span>{{username}}</span>
                <div class="avatar-span" v-if="admin">Admin</div>
            </div>
        </div>
      </div>
      <!-- Navbar content -->
    </nav>
  </div>
</template>

<style>
.avatar {
    color:#fff;
    margin-top: 0px;
    width:50px;
    height:50px;
}
.avatar span {
    color:#fff;
    font-size: 12px;
    position:relative; left:0px; top:-10px;
}
.avatar-span {
    color:#fff;
    font-size: 10px;
    position:relative; left:0px; top:-84px;
}
</style>

<script>
import * as Common from '../modules/common.js'
import {Avataaars} from '../modules/avataaar.js'

function GetAvatar(obj) {
  var o = Object.assign(obj, {
      width: 50,
      height: 50,
      style: "circle",
      svgBackground: "",
  });
  var svg = Avataaars.create(o);
  return svg;
}

export default {
  //runtimeCompiler: true,
  name: 'NavBar',
  components: {
  },
  props: {
      items: Array,
  },
  created() {
    console.log(this.items)
    var token = Common.GetToken();
    if (token != "") {
        var t = Common.DecodeToken(token);
        this.admin = t.admin;
        if (t.authorized == true) {
            var vm=this;
            Common.AJAX_GetJSON("https://api.narco-dons.com/user/avatar", {}, function(data) {
                var a = JSON.parse(data).avatar;
                console.log()
                vm.avatar = GetAvatar(a)
            });
            console.log(t.user_id)
            this.loggedIn = true;
            this.username = t.username;
        }
    }
  },
  methods: {
    SwitchToSignup: function() {
        window.location.href = '/signup/';
    },
    SwitchToLogin: function() {
        window.location.href = '/login/';
    },
    SwitchToLogout: function() {
        Common.ClearToken();
        window.location.href = '/';
    },
  },
  data() {
    return {
        loggedIn: false,
        avatar: null,
        username: "",
        admin: false,
    };
  }     
}
</script>
