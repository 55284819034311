<template>
  <Banner msg=""/>
  <div><span class="white-text">{{ message }}</span></div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
#main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.TitleFont {
    font-family: 'Bebas Neue', cursive;
    font-size: 64px;
}
</style>

<script>
import Banner from '../components/banner.vue'
import * as Common from '../modules/common.js'

export default {
  name: 'VerifyEmail',
  //runtimeCompiler: true,
  components: {
    Banner,
  },
  created: function(){
    var vm=this;
    const urlParams = new URLSearchParams(window.location.search);
    const tokenString = urlParams.get('token');
    console.log(tokenString);
    var url = "https://api.narco-dons.com/user/verifyemail/"+tokenString
    Common.POST_JSON(url, {}, function(data, success) {
      if (success) {
        vm.message = "Thanks for verifing your email address! In a few seconds you will be redirected to the game profile.";
        Common.SetToken(data.access_token);
        console.log(data.access_token);
        var token = Common.DecodeToken(data.access_token);
        setTimeout(function() {
          document.location.href = "/signup/";
        }, 5000);
      } else {
        vm.message = data;
      }
    });
/*    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.responseType = 'json';
    xhr.onload = function (data) {
      if (xhr.status == 200 || xhr.status == 201) {
        vm.message = "Thanks for verifing your email address!";
        Common.SetToken(data.access_token);
        console.log(data.access_token);
        var token = Common.DecodeToken(data.access_token);
        document.location.href = "/index/";
        if (token.status == "VERIFY")
            vm.$parent.swapComponent('VerifyEmail')
        else if (token.status == "COUNTRY")
            vm.$parent.swapComponent('Country')
        else if (token.status == "AVATAR")
            vm.$parent.swapComponent('Avatar')
        else
            document.location.href = "/game/";

      } else {
        vm.message = xhr.response;
      }
      console.log(xhr.response);
    };
    xhr.send({});*/
  },
  methods: {
    changeSetting: function(index) {
      //data.settingsSelected = data.settings[index];
    },
    swapComponent: function(component) {
      console.log(component)
      this.RegisterComponent = component;
    }
  },
  data() {
    return {
      message:"",
    };
  }     
}
</script>
