<template>
  <img class="logo" alt="Narco Dons Logo" src="../assets/images/logo_old.png">
</template>

<script>
export default {
    name: 'Banner',
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.logo {
}
</style>
