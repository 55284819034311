export function SetToken(token) {
    window.localStorage.setItem("token", token);
}

export function GetToken() {
    if (window.localStorage.getItem("token") === null)
        return "";
    else
        return window.localStorage.getItem("token");
}

export function ClearToken(token) {
    window.localStorage.removeItem("token");
}

export function DecodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function POST_FORM(url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization','Basic ' + GetToken());
    xhr.responseType = 'json';
    xhr.onload = function () {
        if (xhr.status == 200 || xhr.status == 201) {
            if (typeof callback === "function")
                callback(xhr.response, true);
            } else {
                callback(xhr.response, false);
            }
        };
    xhr.send(data);
 }
 
 export function AJAX_GetJSON(url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization','Basic ' + GetToken());
    xhr.responseType = 'json';
    xhr.onload = function () {
        if (xhr.status == 200 || xhr.status == 201) {
            if (typeof callback === "function")
                callback(xhr.response, true);
        } else {
            console.log(xhr.response, false);
        }
    };
    xhr.send(data);
}

export function POST_JSON(url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization','Basic ' + GetToken());
    xhr.responseType = 'json';
    xhr.onload = function () {
        if (xhr.status == 200 || xhr.status == 201) {
            if (typeof callback === "function")
                callback(xhr.response, true);
        } else {
            if (typeof callback === "function")
                callback(xhr.response, false);
        }
    };
    xhr.send(JSON.stringify(data));
}
